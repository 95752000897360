import React from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { forwardRef } from "react";
import Switch from "react-switch";

const SwitchEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.values.list && props.values.list[0] ? props.value === props.values.list[0] : props.value === "Yes");

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        };
    });

    const checkedIcon = <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100%",fontSize: 12,color: "#fff",paddingRight: 2}}>{" "}{props.values?.list ? props.values.list[0] || "Yes" : "Yes"}</div>
    const uncheckedIcon = <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100%",fontSize: 12,color: "#fff",paddingRight: 2}}>{" "}{props.values?.list ? props.values.list[1] || "No" : "No"}</div>

    return (
        <div style={{paddingLeft:'10px'}}>
            <Switch onColor="#38a4f8" uncheckedIcon={uncheckedIcon} checkedIcon={checkedIcon} checked={value} onChange={()=>setValue(!value)} />
        </div>
    );
});
SwitchEditor.displayName = "SwitchEditor";

export default SwitchEditor;