import http from "../core/http";
const group = 'properties';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getCategoryListRequest =  async (data) => {
	return await http.post(`${group}/getCategoryList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getSubCategoryListRequest =  async (data) => {
	return await http.post(`${group}/getSubCategoryList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getAtollListRequest =  async (data) => {
	return await http.post(`${group}/getAtollList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getIslandListRequest =  async (data) => {
	return await http.post(`${group}/getIslandList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getAmenityListRequest =  async (data) => {
	return await http.post(`${group}/getAmenityList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getThingsTodoListRequest =  async (data) => {
	return await http.post(`${group}/getThingsTodoList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getPlacesToVisitListRequest =  async (data) => {
	return await http.post(`${group}/getPlacesToVisitList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const deletePropertyImageRequest =  async (data) => {
	return await http.post(`${group}/deletePropertyImage`, data).then(res => res.data).catch(http.defaultCatch);
}
