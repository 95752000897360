import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getPropertyListRequest, getRoomListRequest, getMealTypeListRequest } from "../../../api/controller/RoomRateController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const roomRateListState = atom({
    key: 'roomRateListState',
    default: [],
})
export const roomRatePropertyState = atom({
	key: 'roomRatePropertyState',
	default:{
		data:[],
		ovf:false,
	}
})
export const roomRateRoomState = atom({
	key: 'roomRateRoomState',
	default:{
		data:[],
		ovf:false,
	}
})
export const roomRateMealTypeState = atom({
	key: 'roomRateMealTypeState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
	{
	  	label: 'Property',
		size: 6,
		offset: 6,
		required: true,
		type: 'select',
		value: '',
		list: []
	}
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const roomRateDataStructureState = selector({
    key: 'roomRateDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Property',
                type: 'select',
                field: 'room.property',
                subFields: { label: 'name', value: 'id' },
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'disabled' }], view: ['same_as:create.0'], 
				list: get(roomRatePropertyState),
				getRequest: getPropertyListRequest,
                child: {
                    name: 'Room',
                    subFields: { label: 'name', value: 'id' },
                    fn: getRoomListRequest,
                }
            },
			{ 
                label: 'Room',
                type: 'select',
                order: { table: 2, form: 2 }, 
                subFields: { label: 'number', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(roomRateRoomState),
				getRequest: getRoomListRequest,
            },
			{ 
                label: 'Meal Type',
                type: 'select',
                order: { table: 3, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(roomRateMealTypeState),
				getRequest: getMealTypeListRequest,
            },
			{ 
                label: 'Date',
                type: 'datepicker',
                order: { table: 4, form: 4 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'From Date',
                type: 'datepicker',
                order: { form: 9.1 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: [{ enabled: false }], view: [{ enabled: false }], 
            },
			{ 
                label: 'To Date',
                type: 'datepicker',
                order: { form: 9.1 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: [{ enabled: false }], view: [{ enabled: false }], 
            },
			{ 
                label: 'Rate Mode',
                type: 'select',
                order: { table: 5, form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: 'per-room' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'per-room', value: 'per-room' },
						{ label: 'per-person', value: 'per-person' },
					]
				},
            },
			{ 
                label: 'Room Rate',
                type: 'number',
                order: { table: 5.5, form: 5.5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-room' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Adult Rate',
                type: 'number',
                order: { table: 6, form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-person' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Child Rate',
                type: 'number',
                order: { table: 7, form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-person' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Infant Rate',
                type: 'number',
                order: { form: 8 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-person' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Extra Adult Rate',
                type: 'number',
                order: { form: 9 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Extra Child Rate',
                type: 'number',
                order: { form: 10 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
