import React from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { forwardRef } from "react";
import InputMask from "react-input-mask";

const MaskEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        };
    });

    return (
        <InputMask
            mask={props.values.mask}
            value={value}
            onChange={event => setValue(event.target.value)}
            style={{width: "100%"}}
            className="form-control input-color"
        />
    );
});
MaskEditor.displayName = "MaskEditor";

export default MaskEditor;