
import { Container } from "reactstrap";
import React from "react";

const Home = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h3>Home - Auth</h3>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Home;

