function changeBodyAttribute(attribute, value) {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
}

function  manageBodyClass (cssClass, action = "toggle") {
    switch (action) {
        case "add":
            if (document.body) document.body.classList.add(cssClass);
            break;
        case "remove":
            if (document.body) document.body.classList.remove(cssClass);
            break;
        default:
            if (document.body) document.body.classList.toggle(cssClass);
            break;
    }

    return true;
}

function changeLayout(layout) {
    try {
        if (layout === 'horizontal') {
            document.body.removeAttribute('data-sidebar');
            document.body.removeAttribute('data-sidebar-size');
        }
        changeBodyAttribute("data-layout", layout);

    } catch (error) { }
}

const changeLeftSidebarTheme = (theme) => {
    try {
        changeBodyAttribute("data-sidebar", theme);
    } catch (error) { }
}
const changeTopbarTheme = (theme) => {
    try {
        changeBodyAttribute("data-topbar", theme);
    } catch (error) { }
}

const changeBodyThemeType = (theme) => {
    try {
        changeBodyAttribute("data-theme", theme);
    } catch (error) { }
}

/**
 * Toggles the rightsidebar
 */
const toggleRightSidebar = () => {
    try {
        manageBodyClass("right-bar-enabled");
    } catch (error) { }
}

/**
 * Show the rightsidebar
 */
const showRightSidebar = () => {
    try {
        manageBodyClass("right-bar-enabled", "add");
    } catch (error) { }
}

/**
 * Hides the rightsidebar
 */
const hideRightSidebar = () => {
    try {
        manageBodyClass("right-bar-enabled", "remove");
    } catch (error) { }
}


const changeLeftSidebarType = (sidebarType, isMobile) => {
    try {
        switch (sidebarType) {
            case "compact":
                changeBodyAttribute("data-sidebar-size", "small");
                manageBodyClass("sidebar-enable", "remove")
                manageBodyClass("vertical-collpsed", "remove")
                break;
            case "icon":
                changeBodyAttribute("data-keep-enlarged", "true");
                manageBodyClass("vertical-collpsed", "add")
                break;
            case "condensed":
                manageBodyClass("sidebar-enable", "add")
                if (!isMobile)  manageBodyClass("vertical-collpsed", "add");
                break;
            default:
                changeBodyAttribute("data-sidebar-size", "");
                manageBodyClass("sidebar-enable", "remove")

                if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
                break;
        }
    } catch (error) { }
}

export {
    changeLeftSidebarType,
    changeLeftSidebarTheme,
    changeTopbarTheme,
    changeBodyThemeType,
    toggleRightSidebar,
    showRightSidebar,
    hideRightSidebar,
    changeLayout,
}
