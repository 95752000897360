import React from 'react';


export const Loader = ({loading}) => {

    return (
        <div id="preloader" style={{position: 'absolute', zIndex:"9", display: loading ? "block" : "none"}}>
            <div id="status" style={{display: loading ? "block" : "none"}}>
            <div className="spinner-chase">
                <div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div>
            </div>
            </div>
        </div>
    )

}