import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/ThingsTodoController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const thingsTodoListState = atom({
    key: 'thingsTodoListState',
    default: [],
})
export const thingsTodoPropertyState = atom({
	key: 'thingsTodoPropertyState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const thingsTodoDataStructureState = selector({
    key: 'thingsTodoDataStructureState',
    get: ({ get }) => (
        [
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Icon',
                type: 'text',
                order: { table: 3, form: 3 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Font',
                type: 'text',
                order: { table: 4, form: 4 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Size',
                type: 'text',
                order: { table: 5, form: 5 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Color',
                type: 'text',
                order: { table: 6, form: 6 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
