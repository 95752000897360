import http from "../core/http";
const group = 'bookings';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}
export const confirmDataRequest =  async (data) => {
    return await http.post(`${group}/confirm`, data).then(res => res.data).catch(http.defaultCatch);
}
export const cancelDataRequest =  async (data) => {
    return await http.post(`${group}/cancel`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getUserListRequest =  async (data) => {
	return await http.post(`${group}/getUserList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getPropertyListRequest =  async (data) => {
	return await http.post(`${group}/getPropertyList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getRoomListRequest =  async (data) => {
	return await http.post(`${group}/getRoomList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getAddonListRequest =  async (data) => {
	return await http.post(`${group}/getAddonList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getMealTypeListRequest =  async (data) => {
	return await http.post(`${group}/getMealTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getTransferTypeListRequest =  async (data) => {
	return await http.post(`${group}/getTransferTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
