import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { mapValues } from "lodash";
import { useSetRecoilState } from "recoil";
import { activeNavState } from "../../state/GlobalState";

const Navbar = (props) => {
    
    const setActiveNav = useSetRecoilState(activeNavState)

    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
          width: undefined,
          height: undefined,
        });
      
        useEffect(() => {
          function handleResize() {
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          }
          window.addEventListener("resize", handleResize);
          handleResize();
          return () => window.removeEventListener("resize", handleResize);
        }, []);  
        return windowSize;
    }

    const size = useWindowSize()

    const getActiveNav = (nav) => {
        if(nav.children.length > 0){
            return nav.children.map((c)=>(getActiveNav(c))).join('') 
        } else if(props.location.pathname.replace(/^\/|\/$/g, '') === nav.link?.replace(/^\/|\/$/g, '')){
            setActiveNav(nav)
            return nav.highlight?.replace(/^\/|\/$/g, '')
        } else {
            return ''
        }
    }

    const setActive = () => {
        var matchingMenuItem = null;
        const highlight = props.navItems.map((m)=>(getActiveNav(m))).join('')
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        const currentPath = props.location.pathname.replace(/^\/|\/$/g, '');
        for (var i = 0; i < items.length; ++i) {
            if (currentPath === items[i].pathname.replace(/^\/|\/$/g, '') || (highlight && items[i].pathname.replace(/^\/|\/$/g, '') === highlight)) {
                matchingMenuItem = items[i];
            } else {
                items[i].classList.remove("active")
                let parent = items[i].parentElement;
                while(parent.id !== "topnav-menu-content"){
                    parent.classList.remove("active")
                    parent = parent.parentElement
                }
            }
        }
        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");
            let parent = matchingMenuItem.parentElement;
            while(parent.id !== "topnav-menu-content"){
                parent.classList.add("active")
                parent = parent.parentElement
            }
        }
    }

    useEffect(() => {
        setActive()
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if(size?.width >= 992 && JSON.stringify(mapValues(menuStates, () => false)) !== JSON.stringify(menuStates)){
            setMenuStates(mapValues(menuStates, () => false))
        }
        // eslint-disable-next-line
    }, [size])

    const [menuStates, setMenuStates] = useState({})

    const toggleMenuGroup = (e, inMenu) => {
        e.preventDefault();
        const menu = inMenu.replace(/ /g, '')
        if(size?.width <= 992){
            setMenuStates({...menuStates, [menu]: !menuStates[menu]})
        }
    }

    const parseSubMenu = (menu, key) => (
        <div key={key} className={classname("dropdown-menu", { show: menuStates[key] })} >
            {menu.children?.filter(c=>!c.hidden).map((child, index) => (
                child.children?.filter(c=>!c.hidden).length > 0 ? (
                    <div key={`${key}-${index}`} className="dropdown">
                        <Link to="menu-item" className="dropdown-item dropdown-toggle arrow-none" onClick={(e) => toggleMenuGroup(e,`${key}-${index}`)}>{child.name} <div className="arrow-down"></div></Link>
                        {parseSubMenu(child,`${key}-${index}`)}
                    </div>
                ) : <Link key={`${key}-${index}`} to={child.link || "/#"} className="dropdown-item">{child.name}</Link>
            ))}
        </div>
    )

    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                        <Collapse isOpen={props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                {props.navItems.filter(c=>!c.hidden).map((item,key)=>(
                                    <li key={key} className="nav-item dropdown">
                                        {!item.children || item.children.filter(c=>!c.hidden).length === 0 ? (
                                            <Link to={item.link} className="nav-link arrow-none"><i className={`${item.icon} mr-2`}></i>{item.name} </Link>
                                        ) : (
                                            <React.Fragment>
                                                <Link to="menu-item" onClick={(e) => toggleMenuGroup(e,key) } className="nav-link dropdown-toggle arrow-none"  >
                                                    <i className={`${item.icon} mr-2`}></i>{item.name} <div className="arrow-down"></div>
                                                </Link>
                                                {parseSubMenu(item,key)}
                                            </React.Fragment>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
       
        </React.Fragment>
    );

}

export default withRouter(Navbar);
