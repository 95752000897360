import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getPropertyListRequest } from "../../../api/controller/AddonController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const addonListState = atom({
    key: 'addonListState',
    default: [],
})
export const addonPropertyState = atom({
	key: 'addonPropertyState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
	{
	  	label: 'Property',
		size: 6,
		offset: 6,
		required: true,
		type: 'select',
		value: '',
		list: []
	}
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const addonDataStructureState = selector({
    key: 'addonDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Property',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(addonPropertyState),
				getRequest: getPropertyListRequest,
            },
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Description',
                type: 'textarea',
                order: { table: 3, form: 3 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Details',
                type: 'text',
                order: { table: 4, form: 4 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Rate Mode',
                type: 'select',
                order: { table: 5, form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: 'per-item' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'per-item', value: 'per-item' },
						{ label: 'per-person', value: 'per-person' },
					]
				},
            },
			{ 
                label: 'Item Rate',
                type: 'number',
                order: { table: 5.5, form: 5.5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-item' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Adult Rate',
                type: 'number',
                order: { table: 6, form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-person' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Child Rate',
                type: 'number',
                order: { table: 7, form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-person' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Infant Rate',
                type: 'number',
                order: { form: 8 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:RateMode,!per-person' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
