
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getPropertiesRequest } from "../../../api/controller/UserController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const userListState = atom({
    key: 'userListState',
    default: [],
})
export const userRoleState = atom({
  key: 'userRoleState',
  default: {
    data: [],
    ovf: false,
  },
})
export const userPropertiesState = atom({
	key: 'userPropertiesState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
  {
    label: 'Active',
      size: 6,
      offset: 6,
      required: true,
      type: 'select',
      value: { label: "Yes", value: true },
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
  }
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const userDataStructureState = selector({
    key: 'userDataStructureState',
    get: ({ get }) => (
        [
          { 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Username",
            type: "text",
            order: { table: 2, form: 2 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Email",
            type: "email",
            order: { table: 3, form: 3 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          { 
            label: "National ID",
            field: "nid",
            type: "text",
            order: { form: 3.1 },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          { 
            label: "Mobile",
            type: "text",
            order: { form: 3.2 },
            create: [{ enabled: true, size: { xs: 12, md: 4 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          {
            label: "Roles",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 5 },
            create: [{ enabled: true, size: { xs: 12, md: 12}, validations: 'hidden_if:UserType,USER' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(userRoleState),
          },
          { 
            label: 'User Type',
            type: 'select',
            order: { table: 3.01, form: 4 }, 
            create: [{ enabled: true, size: { xs: 12, md: 4 }, default: 'ADMIN'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            rootValue: true,
            list: {
              ovf: false,
              data:[
                { label: 'USER', value: 'USER' },
                { label: 'ADMIN', value: 'ADMIN' },
                { label: 'SA', value: 'SA' },
              ]
            },
          },
          { 
            label: "Notification Token",
            type: "text",
            order: { form: 5.2 },
            create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          { 
            label: 'Properties',
            type: 'multiselect',
            order: { form: 6.1 }, 
            create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'hidden_if:UserType,!ADMIN' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            list: get(userPropertiesState),
            getRequest: getPropertiesRequest,
          },
          {
            label: "Send Notification",
            type: "switch",
            order: { table: 3.1, form: 7 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 6, md: 3 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          {
            label: "Send Email",
            type: "switch",
            order: { table: 3.2, form: 7 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 6, md: 3 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          {
            label: "Active",
            type: "switch",
            order: { form: 7 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 6, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          // {
          //   label: "Domain User",
          //   type: "switch",
          //   order: { table: 5, form: 7 },
          //   table: { editable: true },
          //   create: [{ enabled: true, size: { xs: 6, md: 3 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          //   list: ['Yes', 'No'],
          // },
          { 
            label: "Password",
            type: "text",
            order: { form: 6.9 },
            create: [{ enabled: true, size: { xs: 12, md: 4 }, default: 'welcome@123', validations: 'readOnly', }],
          },
          { 
            label: 'Addresses',
            type: 'repeater',
            order: { form: 10 },
            create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: { xs: 2, md: 1 }, repeatLabel: true, validations: 'hidden_if:UserType,!USER' }], update: ['same_as:create.0'], view: ['same_as:create.0'],
            children: [
              { 
                label: 'Island',
                type: 'text',
                order: { form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
              },
              { 
                label: 'Address1',
                type: 'text',
                order: { form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 8 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
              },
              { 
                label: 'Address2',
                type: 'text',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
              },
              { 
                label: 'Default',
                type: 'switch',
                order: { form: 10 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4, lg: 2 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                list: ['Yes', 'No'],
              },
            ]
          }
        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})