import http from "../core/http";
const group = 'rooms';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getPropertyListRequest =  async (data) => {
	return await http.post(`${group}/getPropertyList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getRoomTypeListRequest =  async (data) => {
	return await http.post(`${group}/getRoomTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getAmenityListRequest =  async (data) => {
	return await http.post(`${group}/getAmenityList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const deleteRoomImageRequest =  async (data) => {
	return await http.post(`${group}/deleteRoomImage`, data).then(res => res.data).catch(http.defaultCatch);
}
