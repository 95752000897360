
import { atom } from "recoil";

export const notificationState = atom({
    key: 'notificationState',
    default: [{
        title: 'Your order is placed.',
        desc: 'If several languages coalesce the grammar',
        link: null,
        createdAt: '2020-01-12-11:15',
        read: false,
        avatar: {
            type: 'icon',
            path: 'bx bx-cart',
            bg: 'bg-primary',
        }
    },{
        title: 'James Lemire',
        desc: 'It will seem like simplified English',
        link: null,
        createdAt: '2020-01-12-11:40',
        read: false,
        avatar: {
            type: 'image',
            path: '/images/users/avatar-3.jpg',
            bg: null,
        }
    },{
        title: 'Your item is shipped',
        desc: 'If several languages coalesce the grammar',
        link: null,
        createdAt: '2020-01-12-11:50',
        read: false,
        avatar: {
            type: 'icon',
            path: 'bx bx-badge-check',
            bg: 'bg-success',
        }
    },{
        title: 'Salena Layfield',
        desc: 'As a skeptical Cambridge friend of mine accidental',
        link: null,
        createdAt: '2020-01-12-09:25',
        read: true,
        avatar: {
            type: 'image',
            path: '/images/users/avatar-4.jpg',
            bg: null,
        }
    }],
})