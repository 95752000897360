import React from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { forwardRef } from "react";
import Flatpickr from "react-flatpickr"

const DateTimeEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.values.value);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        };
    });

    const getDatepickerAttr = () => {
        const options = {...props.values.options}
        let onChange = (_,e) => setValue(e)
        if(options.mode === "range"){
          onChange = (_,e) => e.split(" to ").length > 1 ? setValue(e.split(" to ")) : false
        } else if(options.mode === "multiple"){
          onChange = (_,e) => setValue(e.split(", "))
        }
        return { value: value, onChange: onChange, options: options, className: " form-control d-block" }
      }

    return (
        <div style={{minWidth: '300px'}}>
            <Flatpickr {...getDatepickerAttr()} />
        </div>
    );
});
DateTimeEditor.displayName = "DateTimeEditor";

export default DateTimeEditor;