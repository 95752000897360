import { atom, selector } from "recoil";
import { createDataRequest, deletePropertyImageRequest, updateDataRequest, getCategoryListRequest, getSubCategoryListRequest, getAtollListRequest, getIslandListRequest, getAmenityListRequest, getThingsTodoListRequest, getPlacesToVisitListRequest } from "../../../api/controller/PropertyController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const propertyListState = atom({
    key: 'propertyListState',
    default: [],
})
export const propertyCategoryState = atom({
	key: 'propertyCategoryState',
	default:{
		data:[],
		ovf:false,
	}
})
export const propertySubCategoryState = atom({
	key: 'propertySubCategoryState',
	default:{
		data:[],
		ovf:false,
	}
})
export const propertyAtollState = atom({
	key: 'propertyAtollState',
	default:{
		data:[],
		ovf:false,
	}
})
export const propertyIslandState = atom({
	key: 'propertyIslandState',
	default:{
		data:[],
		ovf:false,
	}
})
export const propertyAmenityState = atom({
	key: 'propertyAmenityState',
	default:{
		data:[],
		ovf:false,
	}
})
export const propertyThingsTodoState = atom({
	key: 'propertyThingsTodoState',
	default:{
		data:[],
		ovf:false,
	}
})
export const propertyPlacesToVisitState = atom({
	key: 'propertyPlacesToVisitState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "xl", wizard: true }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const propertyDataStructureState = selector({
    key: 'propertyDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Category',
                type: 'select',
                field: 'sub_category.category',
                order: { table: 1, form: 1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertyCategoryState),
				getRequest: getCategoryListRequest,
                child: {
                    name: 'SubCategory',
                    subFields: { label: 'name', value: 'id' },
                    fn: getSubCategoryListRequest,
                }
            },
			{ 
                label: 'Sub Category',
                type: 'select',
                order: { table: 2, form: 2 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'disabled_if:Category,null'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertySubCategoryState),
				getRequest: getSubCategoryListRequest,
            },
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 3, form: 3 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Short Description',
                type: 'text',
                order: { form: 4 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Amenities',
                type: 'multiselect',
                order: { form: 4.1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertyAmenityState),
				getRequest: getAmenityListRequest,
            },
			{ 
                label: 'Things Todos',
                type: 'multiselect',
                order: { form: 4.2 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertyThingsTodoState),
				getRequest: getThingsTodoListRequest,
            },
			{ 
                label: 'Places To Visit',
                type: 'multiselect',
                order: { form: 4.3 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertyPlacesToVisitState),
				getRequest: getPlacesToVisitListRequest,
            },
			{ 
                label: 'Long Description',
                type: 'textarea',
                order: { form: 5 }, 
                section: { id: 2, label: 'Details' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '1000', rows: '4' }   }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Details',
                type: 'textarea',
                order: { form: 6 }, 
                section: { id: 2, label: 'Details' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '5000', rows: '5' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Cancellation Policy',
                type: 'textarea',
                order: { form: 6.1 }, 
                section: { id: 2, label: 'Details' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '1000', rows: '5' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Atoll',
                type: 'select',
                order: { form: 7 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertyAtollState),
				getRequest: getAtollListRequest,
                child: {
                    name: 'Island',
                    fn: getIslandListRequest,
                }
            },
			{ 
                label: 'Island',
                type: 'select',
                order: { table: 4, form: 8 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(propertyIslandState),
				getRequest: getIslandListRequest,
            },
			{ 
                label: 'Address1',
                type: 'text',
                order: { form: 9 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Address2',
                type: 'text',
                order: { form: 10 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Postal',
                type: 'text',
                order: { form: 11 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Geo Latitude',
                type: 'text',
                order: { form: 12 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Geo Longitude',
                type: 'text',
                order: { form: 13 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Telephone',
                type: 'text',
                order: { form: 14 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Mobile',
                type: 'text',
                order: { table: 7, form: 15 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Email',
                type: 'text',
                order: { table: 8, form: 16 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Website',
                type: 'text',
                order: { form: 17 }, 
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Checkin',
                field: 'checkin_f',
                type: 'datepicker',
                order: { form: 18 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'H:i', options: { enableTime: true, noCalendar: true, dateFormat: 'H:i', altFormat: 'H:i', time_24hr: true } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Checkout',
                field: 'checkout_f',
                type: 'datepicker',
                order: { form: 19 }, 
                section: { id: 4, label: 'Others' },
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { enableTime: true, noCalendar: true, dateFormat: 'H:i', altFormat: 'H:i', time_24hr: true } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Star Ranking',
                type: 'number',
                order: { }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Payment Term',
                type: 'number',
                order: { form: 20 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "days"  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Service Charge Rate',
                type: 'number',
                order: { form: 20.1 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Tax Type',
                type: 'select',
                order: { form: 21 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'GST', value: 'GST' },
						{ label: 'T-GST', value: 'T-GST' },
					]
				},
            },
			{ 
                label: 'Tax Amount',
                type: 'number',
                order: { form: 22 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Bed Tax Amount',
                type: 'number',
                order: { form: 22.1 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Display Rate',
                type: 'number',
                order: { form: 23 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Display Currency',
                type: 'select',
                order: { form: 24 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: '$', value: '$' },
                        { label: 'MVR', value: 'MVR' },
					]
				},
            },
			{ 
                label: 'App Startup Featured',
                type: 'switch',
                order: { form: 25 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 6, md: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
                permission: 'featuring'
            },
			{ 
                label: 'Active',
                type: 'switch',
                order: { table: 9, form: 25.1 }, 
                section: { id: 4, label: 'Others' },
                create: [{ enabled: true, size: { xs: 6, md: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Property Images',
                type: 'dropzone',
                order: { form: 26 }, 
                section: { id: 5, label: 'Uploads' },
                subFields: { label: 'url', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				deleteFn: deletePropertyImageRequest,
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
