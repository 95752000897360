import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
import Home from "../app/auth/Home";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
import NonAuthLayout from "../components/NonAuthLayout";
import Reviews from "../app/auth/reviews"
import Notifications from "../app/auth/notifications"
import TransferTypes from "../app/auth/transferTypes"
import TransferRates from "../app/auth/transferRates"
import Bookings from "../app/auth/bookings"
import PlacesToVisits from "../app/auth/placesToVisits"
import ThingsTodos from "../app/auth/thingsTodos"

import RoomRates from "../app/auth/roomRates"
import Helps from "../app/auth/helps"
import Coupons from "../app/auth/coupons"
import Addons from "../app/auth/addons"
import Rooms from "../app/auth/rooms"
import Properties from "../app/auth/properties"
import Amenities from "../app/auth/amenities"
import RoomTypes from "../app/auth/roomTypes"
import MealTypes from "../app/auth/mealTypes"
import Categories from "../app/auth/categories"
import Islands from "../app/auth/islands"
import Atolls from "../app/auth/atolls"
import Advertisements from "../app/auth/advertisements"



// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";


const navigation = [
    {
        name: 'Login',
        icon: 'bx bx-lock',
        link: '/login',
        defaultRoute: true,
        component: Login,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Forgot Password',
        icon: 'bx bx-lock',
        link: '/forgot-password',
        component: ForgotPassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Change Password',
        icon: 'bx bx-lock',
        link: '/change-password',
        component: ChangePassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Dashboard',
        icon: 'mdi mdi-monitor-dashboard',
        link: '/home',
        defaultRoute: true,
        component: Home,
        middleware: 'auth',
        children: [],
    },
    {
        name: 'Main',
        icon: "bx bx-group",
        children: [
            { // Booking Route Begin
                name: 'Booking',
                icon: 'mdi mdi-notebook-edit',
                link: '/bookings',
                component: Bookings,
                middleware: 'auth',
                permission: 'read-bookings',
                children: [],
            }, // Booking Route End
        ]
    },
    {
        name: 'Rates',
        icon: "bx bx-group",
        children: [
            { // RoomRate Route Begin
                name: 'Room Rate',
                icon: 'mdi mdi-cash-multiple',
                link: '/roomRates',
                component: RoomRates,
                middleware: 'auth',
                permission: 'read-roomRates',
                children: [],
            }, // RoomRate Route End
            { // TransferRate Route Begin
                name: 'Transfer Rate',
                icon: 'mdi mdi-transit-transfer',
                link: '/transferRates',
                component: TransferRates,
                middleware: 'auth',
                permission: 'read-transferRates',
                children: [],
            }, // TransferRate Route End
            
        ]
    },	
    {
        name: 'Master',
        icon: "bx bx-group",
        children: [
            
            {
                name: 'Master L1',
                icon: "bx bx-group",
                children: [
                    { // Property Route Begin
                        name: 'Property',
                        icon: 'mdi mdi-office-building',
                        link: '/properties',
                        component: Properties,
                        middleware: 'auth',
                        permission: 'read-properties',
                        children: [],
                    }, // Property Route End
                    { // Room Route Begin
                        name: 'Room',
                        icon: 'mdi mdi-bed-king-outline',
                        link: '/rooms',
                        component: Rooms,
                        middleware: 'auth',
                        permission: 'read-rooms',
                        children: [],
                    }, // Room Route End
                    { // Coupon Route Begin
                        name: 'Coupon',
                        icon: 'mdi mdi-ticket-percent',
                        link: '/coupons',
                        component: Coupons,
                        middleware: 'auth',
                        permission: 'read-coupons',
                        children: [],
                    }, // Coupon Route End
                    { // Addon Route Begin
                        name: 'Addon',
                        icon: 'mdi mdi-circle-multiple-outline',
                        link: '/addons',
                        component: Addons,
                        middleware: 'auth',
                        permission: 'read-addons',
                        children: [],
                    }, // Addon Route End
                ]
            },
            {
                name: 'Master L2',
                icon: "bx bx-group",
                children: [
                    { // Help Route Begin
                        name: 'Help',
                        icon: 'mdi mdi-frequently-asked-questions',
                        link: '/helps',
                        component: Helps,
                        middleware: 'auth',
                        permission: 'read-helps',
                        children: [],
                    }, // Help Route End
                    { // Advertisement Route Begin
                        name: 'Advertisement',
                        icon: 'bx bx-image-add',
                        link: '/advertisements',
                        component: Advertisements,
                        middleware: 'auth',
                        permission: 'read-advertisements',
                        children: [],
                    }, // Advertisement Route End
                    { // Atoll Route Begin
                        name: 'Atoll',
                        icon: 'mdi mdi-island',
                        link: '/atolls',
                        component: Atolls,
                        middleware: 'auth',
                        permission: 'read-atolls',
                        children: [],
                    }, // Atoll Route End
                    { // Island Route Begin
                        name: 'Island',
                        icon: 'mdi mdi-beach',
                        link: '/islands',
                        component: Islands,
                        middleware: 'auth',
                        permission: 'read-islands',
                        children: [],
                    }, // Island Route End
                    { // Category Route Begin
                        name: 'Category',
                        icon: 'mdi mdi-shape-outline',
                        link: '/categories',
                        component: Categories,
                        middleware: 'auth',
                        permission: 'read-categories',
                        children: [],
                    }, // Category Route End
                    { // MealType Route Begin
                        name: 'Meal Type',
                        icon: 'mdi mdi-food',
                        link: '/mealTypes',
                        component: MealTypes,
                        middleware: 'auth',
                        permission: 'read-mealTypes',
                        children: [],
                    }, // MealType Route End
                    { // TransferType Route Begin
                        name: 'Transfer Type',
                        icon: 'mdi mdi-swap-horizontal',
                        link: '/transferTypes',
                        component: TransferTypes,
                        middleware: 'auth',
                        permission: 'read-transferTypes',
                        children: [],
                    }, // TransferType Route End
                    { // RoomType Route Begin
                        name: 'Room Type',
                        icon: 'mdi mdi-bed-double',
                        link: '/roomTypes',
                        component: RoomTypes,
                        middleware: 'auth',
                        permission: 'read-roomTypes',
                        children: [],
                    }, // RoomType Route End
                    { // Amenity Route Begin
                        name: 'Amenity',
                        icon: 'mdi mdi-asterisk',
                        link: '/amenities',
                        component: Amenities,
                        middleware: 'auth',
                        permission: 'read-amenities',
                        children: [],
                    }, // Amenity Route End
                    { // ThingsTodo Route Begin
                        name: 'Things Todo',
                        icon: 'mdi mdi-ticket',
                        link: '/thingsTodos',
                        component: ThingsTodos,
                        middleware: 'auth',
                        permission: 'read-thingsTodos',
                        children: [],
                    }, // ThingsTodo Route End
                    { // PlacesToVisit Route Begin
                        name: 'Places To Visit',
                        icon: 'mdi mdi-fireplace-off',
                        link: '/placesToVisits',
                        component: PlacesToVisits,
                        middleware: 'auth',
                        permission: 'read-placesToVisits',
                        children: [],
                    }, // PlacesToVisit Route End
                ]
            },
        ]
    },
	{ // Notification Route Begin
		name: 'Notification',
		icon: 'mdi mdi-monitor-dashboard',
		link: '/notifications',
		component: Notifications,
		middleware: 'auth',
		permission: 'read-notifications',
		children: [],
	}, // Notification Route End
	{ // Review Route Begin
		name: 'Review',
		icon: 'mdi mdi-monitor-dashboard',
		link: '/reviews',
		component: Reviews,
		middleware: 'auth',
		permission: 'read-reviews',
		children: [],
	}, // Review Route End
	// Navigations
    
    {
        name: 'ACL',
        icon: "bx bx-group",
        children: [
            {
                name: 'Users',
                icon: 'bx bx-group',
                link: '/acl/users',
                component: Users,
                middleware: 'auth',
                permission: 'read-users',
                children: []
            },
            {
                name: 'Roles',
                icon: 'mdi mdi-account-key-outline',
                link: '/acl/roles',
                component: Roles,
                middleware: 'auth',
                permission: 'read-roles',
                children: []
            },
            {
                name: 'Permissions',
                icon: 'bx bx-lock-open-alt',
                link: '/acl/permissions',
                component: Permissions,
                middleware: 'auth',
                permission: 'read-permissions',
                children: []
            },
        ]
    },
];


const megaMenu = [
    // {
    //     name: 'UI Components',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Lightbox',
    //             link: '/lightbox',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Range Slider',
    //             link: '/rangeslider',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Applications',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Ecommerce',
    //             link: '/ecommerce',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Calendar',
    //             link: '/calendar',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Extra Pages',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Maintenance',
    //             link: '/maintenance',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Coming Soon',
    //             link: '/comingsoon',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // }
]

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});


const rawMegaMenuState = atom({
    key: 'rawMegaMenuState',
    default: megaMenu,
});





export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        const parseModule = (nav) => {
            let returnVal = null
            if(nav.children.length > 0){
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest" )) || !nav.middleware){
                    let tempChildren = []
                    nav.children.forEach((child)=>{
                        let temp = parseModule(child)
                        if(temp){
                            tempChildren = [...tempChildren, temp]
                        }
                    })
                    returnVal = {...nav, children: tempChildren}
                }
            } else {
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest")) || !nav.middleware){
                    returnVal = nav
                }
            }
            return returnVal
        }
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>parseModule(header)).filter(n=>n!==null);
        // Remove Empty Parents
        const filterChildren = (nav) => {
            if(nav.children.filter(c=>c.children.length > 0).length > 0){
                nav.children = nav.children.map((child)=>{
                    child = filterChildren(child)
                    return child
                })
            } else {
                nav.children = nav.children.filter(c=>(c.children.length > 0 || c.component))
            }
            return nav
        }
        tempNavigation = tempNavigation.map((header)=>filterChildren(header));
        // Remove Empty Modules
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0 || header.component))
        return tempNavigation;
    }
})

export const megaMenuState = selector({
    key: 'megaMenuState',
    get: ({ get }) => {
        let tempMegaMenu = cloneDeep(get(rawMegaMenuState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        tempMegaMenu = tempMegaMenu.map((header)=>{
            header.children = header.children.filter(c=>(isAuth && c.middleware === "auth" && (!c.permission || permissions.includes(c.permission))) || (!isAuth && (c.middleware === "guest")) || !c.middleware)
            return header
        }).filter(header=>header.children.length > 0);
        return tempMegaMenu;
    }
})
