import { debounce, get } from "lodash";
import React, { useEffect, useRef } from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { forwardRef } from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from "react-select/async";

const SelectEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.values.type !== 'multiselect' ? props.values.list.data.find(e=>props.value === e.label || parseInt(props.value) === parseInt(e.label)) : props.values.list.data.filter(e=>props.value.split(",").indexOf(e.label) > -1));
    const refInput = useRef(null);

    useEffect(() => {
        refInput.current.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return props.values.type === 'multiselect' ? value.map(v=>v.value) : value.value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        };
    });

    const formatSelectRow = (row, column) => (
        { label: get(row, column.subFields?.label || "name"), value: get(row, column.subFields?.value || "id") }
    )
    
    const loadOptions = props.values.list?.ovf && props.values.getRequest ? debounce((inputValue,callback)=>{
        props.values.getRequest({SearchQuery: inputValue || ""}).then(response=>{
          callback(response?.data?.map((val)=>(formatSelectRow(val, props.values))))
        });
      }, 300) : null;

    return (
        <div style={{minWidth: '300px', color:"black"}}>
            {props.values.creatable ? <CreatableSelect ref={refInput} value={value} onChange={(e)=>setValue(e)} options={props.values.list.data} classNamePrefix="select2-selection" isClearable={true} isMulti={props.values.type === 'multiselect'} closeMenuOnSelect={props.values.type !== 'multiselect'}  /> 
            : props.values.list?.ovf ? <AsyncSelect ref={refInput} value={value} onChange={(e)=>setValue(e)} loadOptions={loadOptions} defaultOptions={props.values.list.data} classNamePrefix="select2-selection" isClearable={true} isMulti={props.values.type === 'multiselect'} closeMenuOnSelect={props.values.type !== 'multiselect'} />
            : <Select ref={refInput} value={value} onChange={(e)=>setValue(e)} options={props.values.list.data} classNamePrefix="select2-selection" isClearable={true} isMulti={props.values.type === 'multiselect'} closeMenuOnSelect={props.values.type !== 'multiselect'} />}
        </div>
    );
});
SelectEditor.displayName = "SelectEditor";

export default SelectEditor;