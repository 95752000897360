import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getUserListRequest } from "../../../api/controller/NotificationController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const notificationListState = atom({
    key: 'notificationListState',
    default: [],
})
export const notificationUserState = atom({
	key: 'notificationUserState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    // update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const notificationDataStructureState = selector({
    key: 'notificationDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Type',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, default: 'simple' }], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'simple', value: 'simple' },
						{ label: 'inApp', value: 'inApp' },
						{ label: 'fullAd', value: 'fullAd' },
					]
				},
            },
			{ 
                label: 'User',
                type: 'select',
                order: { table: 2, form: 2 }, 
                subFields: { label: 'identifier', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], view: ['same_as:create.0'], 
				list: get(notificationUserState),
				getRequest: getUserListRequest,
            },
			{ 
                label: 'Player Id',
                type: 'text',
                order: { form: 3 }, 
                create: [{enabled: false}], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Title',
                type: 'text',
                order: { table: 3, form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Message',
                type: 'textarea',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], view: ['same_as:create.0'], 
            },
            {
                label: 'Image',
                type: 'file',
                order: { form: 6 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'hidden_if:Type,!fullAd' }], view: ['same_as:create.0'],
            },
			{ 
                label: 'Sent',
                type: 'datepicker',
                order: { table: 4 }, 
				table: { dateFormat: 'MMM Do, HH:mm', postDateFormat:'YYYY-MM-DD HH:mm' },
                create: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Seen',
                type: 'switch',
                order: { table: 5 }, 
                create: [{ enabled: false }], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
