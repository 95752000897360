import { atom, selector } from "recoil";
import { createDataRequest, deleteRoomImageRequest, updateDataRequest, getPropertyListRequest, getRoomTypeListRequest, getAmenityListRequest } from "../../../api/controller/RoomController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const roomListState = atom({
    key: 'roomListState',
    default: [],
})
export const roomPropertyState = atom({
	key: 'roomPropertyState',
	default:{
		data:[],
		ovf:false,
	}
})
export const roomRoomTypeState = atom({
	key: 'roomRoomTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const roomAmenityState = atom({
	key: 'roomAmenityState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
	{
	  	label: 'Property',
		size: 6,
		offset: 6,
		required: true,
		type: 'select',
		value: '',
		list: []
	}
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const roomDataStructureState = selector({
    key: 'roomDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Property',
                type: 'select',
                order: { form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'disabled' }], view: ['same_as:create.0'], 
				list: get(roomPropertyState),
				getRequest: getPropertyListRequest,
            },
			{ 
                label: 'Room Type',
                type: 'select',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(roomRoomTypeState),
				getRequest: getRoomTypeListRequest,
            },
			{ 
                label: 'Number',
                type: 'text',
                order: { table: 3, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Details',
                type: 'textarea',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Amenities',
                type: 'multiselect',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(roomAmenityState),
				getRequest: getAmenityListRequest,
            },
			{ 
                label: 'Max Adult',
                type: 'number',
                order: { table: 4, form: 6 }, 
                create: [{ enabled: true, size: { xs: 6, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Max Child',
                type: 'number',
                order: { table: 5, form: 7 }, 
                create: [{ enabled: true, size: { xs: 6, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Infant Allowed',
                type: 'switch',
                order: { table: 6, form: 8 }, 
                create: [{ enabled: true, size: { xs: 6, md: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Serviceable',
                type: 'switch',
                order: { table: 7, form: 9 }, 
                create: [{ enabled: true, size: { xs: 6, md: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Smoking Allowed',
                type: 'switch',
                order: { table: 8, form: 10 }, 
                create: [{ enabled: true, size: { xs: 6, md: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Active',
                type: 'switch',
                order: { table: 9, form: 11 }, 
                create: [{ enabled: true, size: { xs: 6, md: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Room Images',
                type: 'dropzone',
                order: { form: 12 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				deleteFn: deleteRoomImageRequest,
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
