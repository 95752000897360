import { atom, selector } from "recoil";
import {  } from "../../../api/controller/ReviewController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const reviewListState = atom({
    key: 'reviewListState',
    default: [],
})
export const reviewPropertyState = atom({
	key: 'reviewPropertyState',
	default:{
		data:[],
		ovf:false,
	}
})
export const reviewUserState = atom({
	key: 'reviewUserState',
	default:{
		data:[],
		ovf:false,
	}
})
export const reviewBookingState = atom({
	key: 'reviewBookingState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ ], 
    update: [ ],
    view: [ { name: 'Default View' } ],
}

export const reviewDataStructureState = selector({
    key: 'reviewDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Property',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
				list: get(reviewPropertyState),
            },
			{ 
                label: 'User',
                type: 'select',
                subFields: { label: 'email', value: 'id' },
                order: { table: 2, form: 2 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
				list: get(reviewUserState),
            },
			{ 
                label: 'Booking',
                type: 'select',
                subFields: { value: 'id', label: 'booking_number' },
                order: { table: 3, form: 3 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
				list: get(reviewBookingState),
            },
			{ 
                label: 'Review',
                type: 'text',
                order: { table: 4, form: 4 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
            },
			{ 
                label: 'Rating',
                type: 'number',
                order: { table: 5, form: 5 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
            },
			{ 
                label: 'Reviewed',
                type: 'switch',
                order: { table: 6, form: 6 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
				list: ['Yes', 'No'],
            },
			{ 
                label: 'Approved',
                type: 'switch',
                order: { table: 7, form: 7 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }],
				list: ['Yes', 'No'],
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
