
export const handleSocket = (currentData, newData, setData, selectKeys={value: 'id', label: 'name'}) => {
    const incomingID = newData.event === "Deleted" ? newData.data : newData.data.id
    const currentIDKey = newData.group === "Select" ? "value" : "id"
    const parsedData = newData.event === "Deleted" || newData.group !== "Select" ? newData.data : { label: newData.data[selectKeys.label], value: newData.data[selectKeys.value] }
    let tempArr = currentData.data
    if(newData.event === "Deleted"){
        setData({...currentData, data: tempArr.filter(i=>parseInt(i[currentIDKey]) !== parseInt(incomingID))})
    } else if(newData.event === "Created"){
        setData({...currentData, data: [...tempArr, parsedData]})
    } else if(newData.event === "Updated"){
        setData({...currentData, data: tempArr.map(i=>parseInt(i[currentIDKey]) !== parseInt(incomingID) ? i : parsedData)})
    }
    
}

export const handleGridSocket = (newData, gridApi, setGridApi, isDisplayedRequest=false, searchParams='', selectKeys={value: 'id', label: 'name'}) => {
    const incomingID = newData.event === "Deleted" ? newData.data : newData.data.id
    const parsedData = newData.event === "Deleted" || newData.group !== "Select" ? newData.data : { label: newData.data[selectKeys.label], value: newData.data[selectKeys.value] }
    if(newData.event === "Deleted"){
        if(gridApi.getRowNode(incomingID)){
            gridApi.applyTransaction({remove: [{id: incomingID}]})
        }
    } else if(newData.event === "Created"){
        if(isDisplayedRequest){
            isDisplayedRequest({id: incomingID, SearchQuery: searchParams}).then(response=>{
                if(response?.IsDisplayed){
                    gridApi.applyTransaction({add: [parsedData]})
                }
            })
        } else {
            gridApi.applyTransaction({add: [parsedData]})
        }
    } else if(newData.event === "Updated"){
        gridApi.getRowNode(incomingID)?.setData(parsedData)
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes()[0]){
            gridApi.getSelectedNodes()[0].setSelected(false)
            setTimeout(()=>{
                if(gridApi?.getRowNode(incomingID)){
                    gridApi.getRowNode(incomingID).setSelected(true)
                }
            }, [50])
        }
    }
    setGridApi(gridApi)
    
}