import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18n
// import { withNamespaces } from 'react-i18next/withNamespace';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  render() {
    const notificationNumber = this.props.notifications.length;
    let unreadNotifications = this.props.notifications.filter(item => item.read === false).length;

    return (
      <React.Fragment>

        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown">
            <i className={"bx bx-bell"+(unreadNotifications > 0 ? " bx-tada" : "")}></i>
            {unreadNotifications > 0 ? <span className="badge badge-danger badge-pill">{unreadNotifications}</span> : null}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {'Notifications'} </h6>
                </Col>
                <div className="col-auto">
                  {this.props.settings.enableViewAll && notificationNumber > 0 ? <a href="#!" className="small"> View All</a> : null}
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: this.props.settings.height }}>
              {notificationNumber > 0 ? this.props.notifications.map((notification,key)=>{
                return (
                  <Link key={key} to="" className="text-reset notification-item">
                    <div className="media">
                      {notification.avatar?.type === "icon" ? (
                        <div className="avatar-xs mr-3">
                          <span className={"avatar-title rounded-circle font-size-16 "+notification.avatar?.bg}>
                            <i className={notification.avatar?.path}></i>
                          </span>
                        </div>
                      ): (
                        <img src={notification.avatar?.path} className="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                      )}
                      
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">{notification.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification.desc}</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{'1 hours ago'} </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              }) : (
                <Link key="0" to="" className="text-reset notification-item">
                  <div className="media">
                    
                    <div className="media-body">
                      <div className="font-size-12 text-muted">
                        <h5 className="mb-0 text-center">{"You have no notifications!"}</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              )}

            </SimpleBar>
              <div className="p-2 border-top">
                {this.props.settings.enableViewAll && notificationNumber > 0 ? (
                    <Link
                      className="btn btn-sm btn-link font-size-14 btn-block text-center"
                      to={this.props.settings.notificationPage}
                    >
                      {" "}
                    {'View all'}{" "}
                    </Link>
                ) : null}
              </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default (NotificationDropdown);
