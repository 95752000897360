import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getPropertyListRequest, getRoomListRequest } from "../../../api/controller/CouponController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const couponListState = atom({
    key: 'couponListState',
    default: [],
})
export const couponPropertyState = atom({
	key: 'couponPropertyState',
	default:{
		data:[],
		ovf:false,
	}
})
export const couponRoomState = atom({
	key: 'couponRoomState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
	{
	  	label: 'Property',
		size: 6,
		offset: 6,
		required: true,
		type: 'select',
		value: '',
		list: []
	}
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const couponDataStructureState = selector({
    key: 'couponDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Property',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'disabled' }], view: ['same_as:create.0'], 
				list: get(couponPropertyState),
				getRequest: getPropertyListRequest,
                child: {
                    name: 'Room',
					subFields: { label: 'name', value: 'id' },
                    fn: getRoomListRequest,
                }
            },
			{ 
                label: 'Room',
                type: 'select',
                order: { table: 2, form: 2 }, 
                subFields: { label: 'number', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'disabled_if:Property,null'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(couponRoomState),
				getRequest: getRoomListRequest,
            },
			{ 
                label: 'Code',
                type: 'text',
                order: { table: 3, form: 3 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 4, form: 4 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Type',
                id: "TX123",
                type: 'select',
                order: { table: 5, form: 5 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'Percentage', value: 'P' },
						{ label: 'Amount', value: 'A' },
					]
				},
            },
			{ 
                label: 'Amount',
                type: 'number',
                order: { table: 6, form: 6 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Start Date',
                type: 'datepicker',
                order: { table: 7, form: 7 }, 
				table: { editable: true, dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD HH:mm', options: { altInput: true, enableTime: true, time_24hr: true, altFormat: 'F j, Y H:i', dateFormat: 'Y-m-d H:i' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'End Date',
                type: 'datepicker',
                order: { table: 8, form: 8 }, 
				table: { editable: true, dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD HH:mm', options: { altInput: true, enableTime: true, time_24hr: true, altFormat: 'F j, Y H:i', dateFormat: 'Y-m-d H:i' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
