
import notify from './Notify';


export const handleResponse = (response) => {
    
    if(response?.Status === "error"){
        if(response.payload?.errors){
          Object.keys(response.payload.errors).forEach(function (key){
            response.payload.errors[key].forEach((val)=>{
              notify({ status: 'error', message: val });
            })
          })
        } else {
          notify({ status: 'error', message: response.Message });
        }
    } else if(response?.Status ==='success') {
        notify({ status: 'success', message: response.Message });
    }
    
}