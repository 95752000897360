import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getUserListRequest, getPropertyListRequest, getRoomListRequest, getAddonListRequest, getMealTypeListRequest, getTransferTypeListRequest } from "../../../api/controller/BookingController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import moment from "moment";

export const bookingListState = atom({
    key: 'bookingListState',
    default: [],
})
export const bookingUserState = atom({
	key: 'bookingUserState',
	default:{
		data:[],
		ovf:false,
	}
})
export const bookingPropertyState = atom({
	key: 'bookingPropertyState',
	default:{
		data:[],
		ovf:false,
	}
})
export const bookingRoomState = atom({
	key: 'bookingRoomState',
	default:{
		data:[],
		ovf:false,
	}
})
export const bookingMealTypeState = atom({
	key: 'bookingMealTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const bookingTransferTypeState = atom({
	key: 'bookingTransferTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const bookingAddonState = atom({
	key: 'bookingAddonState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "xl", wizard: true }

export const filtersDefault = [
	{
	  	label: 'Property',
		size: 6,
		offset: 6,
		required: true,
		type: 'select',
		value: '',
		list: []
	}
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' }, { name: 'Bill View' } ],
}

export const bookingDataStructureState = selector({
    key: 'bookingDataStructureState',
    get: ({ get }) => (
        [

			{ 
				id: 'X123',
                label: 'Property',
                type: 'select',
                order: { form: 2 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }, validations: 'disabled' }], view: ['same_as:create.0', 'same_as:create.0'], 
				list: get(bookingPropertyState),
				getRequest: getPropertyListRequest,
				child: {
					name: 'Room',
					subFields: { label: 'name', value: 'id' },
					fn: getRoomListRequest,
				},
				child2: {
					name: 'Addon',
					subFields: { label: 'name', value: 'id' },
					fn: getAddonListRequest,
				}
            },
			{ 
                label: 'Booking Number',
                type: 'text',
                order: { table: 0.5 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 3  }, default: 1  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
            { 
                label: 'User',
                type: 'select',
                order: { form: 1 }, 
                section: { id: 1, label: 'General' },
				subFields: { label: 'identifier', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', 'same_as:create.0'], 
				list: get(bookingUserState),
				getRequest: getUserListRequest,
            },
			{ 
                label: 'Room',
                type: 'select',
                order: { form: 3 }, 
                section: { id: 1, label: 'General' },
                subFields: { label: 'number', value: 'id' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', 'same_as:create.0'], 
				list: get(bookingRoomState),
				getRequest: getRoomListRequest,
            },
			{ 
                label: 'Room Type',
                type: 'text',
				field: 'room.room_type.name',
                order: { table: 3 }, 
                create: [{ enabled: false  }], update: ['same_as:create.0'], view: ['same_as:create.0', 'same_as:create.0'], 
            },
			{ 
                label: 'Meal Type',
                type: 'select',
                order: { table: 4, form: 4 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
				list: get(bookingMealTypeState),
				getRequest: getMealTypeListRequest,
            },
			{ 
                label: 'Quantity',
                type: 'number',
                order: { table: 5, form: 7.1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 3  }, default: 1  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'From Date',
                type: 'datepicker',
                order: { table: 6, form: 6 }, 
                section: { id: 1, label: 'General' },
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }, default: moment().format("YYYY-MM-DD") , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'To Date',
                type: 'datepicker',
                order: { table: 7, form: 7 }, 
                section: { id: 1, label: 'General' },
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Adult Count',
                type: 'number',
                order: { table: 8, form: 8 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 6, md: 3, lg: 2 }, default: 1  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Child Count',
                type: 'number',
                order: { form: 9 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 6, md: 3, lg: 2 }, default: '0'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Infant Count',
                type: 'number',
                order: { form: 10 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 6, md: 3, lg: 2 }, default: '0'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Extra Adult',
                type: 'number',
                order: { form: 11 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 6, md: 3, lg: 2 }, default: '0' }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Extra Child',
                type: 'number',
                order: { form: 11.1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 6, md: 3, lg: 2 }, default: '0' }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Guest Name',
                type: 'text',
                order: { table: 9, form: 12 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Guest Email',
                type: 'email',
                order: { form: 13 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
                label: 'Guest Phone',
                type: 'text',
                order: { form: 14 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            },
			{ 
				label: 'Payment Method',
				type: 'select',
				order: { form: 14.1 }, 
                section: { id: 1, label: 'General' },
				create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }, default: 'Cash'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'Cash', value: 'Cash' },
						{ label: 'Card', value: 'Card' },
						{ label: 'Bank Transfer', value: 'Bank Transfer' },
					]
				},
			},
			{ 
				label: 'Payment Currency',
				type: 'select',
				order: { form: 14.2 }, 
                section: { id: 1, label: 'General' },
				create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }, default: 'MVR'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'MVR', value: 'MVR' },
						{ label: 'USD', value: 'USD' },
					]
				},
			},
			{ 
                label: 'Transfer Type',
                type: 'select',
                order: { form: 14.3 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
				list: get(bookingTransferTypeState),
				getRequest: getTransferTypeListRequest,
            },
			{ 
                label: 'Status',
                type: 'select',
                order: { table: 10, form: 15 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'pending', value: 'pending' },
						{ label: 'confirmed', value: 'confirmed' },
						{ label: 'cancelled', value: 'cancelled' },
					]
				},
            },
			{ 
                label: 'Pax Details',
                type: 'repeater',
                order: { form: 16 },
                section: { id: 3, label: 'Guest Details' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: { xs: 2, md: 1 }, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }],
                children: [
                    { 
                		label: 'Type',
                		type: 'select',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 2 }, default: 'Adult'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
						rootValue: true,
						list: {
							ovf: false,
							data:[
								{ label: 'Adult', value: 'Adult' },
								{ label: 'Child', value: 'Child' },
								{ label: 'Infant', value: 'Infant' },
							]
						},
            		},
					{ 
                		label: 'Initial',
                		type: 'select',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 2 }, default: 'Mr'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
						rootValue: true,
						list: {
							ovf: false,
							data:[
								{ label: 'Mr', value: 'Mr' },
								{ label: 'Ms', value: 'Ms' },
								{ label: 'Mrs', value: 'Mrs' },
								{ label: 'Miss', value: 'Miss' },
								{ label: 'Dr', value: 'Dr' },
								{ label: 'Prof', value: 'Prof' },
							]
						},
            		},
					{ 
                		label: 'First Name',
                		type: 'text',
                		order: { form: 3 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
					{ 
                		label: 'Middle Name',
                		type: 'text',
                		order: { form: 4 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 2 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
					{ 
                		label: 'Last Name',
                		type: 'text',
                		order: { form: 5 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
					{ 
                		label: 'Doc Type',
                		type: 'select',
                		order: { form: 6 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }, default: 'Passport'  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
						rootValue: true,
						list: {
							ovf: false,
							data:[
								{ label: 'Passport', value: 'Passport' },
								{ label: 'ID Card', value: 'ID Card' },
								{ label: 'Driving License', value: 'Driving License' },
								{ label: 'Other', value: 'Other' },
							]
						},
            		},
					{ 
                		label: 'Doc Number',
                		type: 'text',
                		order: { form: 7 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 2 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
					{ 
                		label: 'Nationality',
                		type: 'text',
                		order: { form: 8 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
					{ 
                		label: 'Transfer',
                		type: 'switch',
                		order: { form: 9 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 4, lg: 2 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
						list: ['Yes', 'No'],
            		},
					{ 
                		label: 'Leader',
                		type: 'switch',
                		order: { form: 10 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 4, lg: 2 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
						list: ['Yes', 'No'],
            		},
					{ 
                		label: 'Remarks',
                		type: 'text',
                		order: { form: 11 }, 
                		create: [{ enabled: true, size: { xs: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
					
                ]
            },
			{ 
                label: 'Addons',
                type: 'repeater',
                order: { form: 16.1 },
                section: { id: 4, label: 'Addons' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: { xs: 2, md: 1 }, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }],
                children: [
					{ 
						label: 'Property',
						type: 'select',
						order: { form: 0.5 }, 
						create: [{ enabled: true, size: { xs: 12 }, validations: 'hidden' }], update: ['same_as:create.0'], view: [{ enabled: false }, { enabled: false }], 
						list: get(bookingPropertyState),
						getRequest: getPropertyListRequest
					},
                    { 
						id: 'X321',
                		label: 'Addon',
                		type: 'select',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 8, lg: 9 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
						rootValue: true,
						list: get(bookingAddonState),
            		},
					{ 
                		label: 'Quantity',
                		type: 'number',
                		order: { form: 12 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 4, lg: 3 }  }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: false }], 
            		},
				]
			},
			{ 
				label: 'Bill Number',
				field: 'bill.bill_number',
				type: 'text',
				order: { form: 100 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
                label: 'Invoice Date',
				field: 'bill.invoice_date',
                type: 'datepicker',
                order: { form: 101 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 }, default: moment().format("YYYY-MM-DD") , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], 
            },
			{ 
                label: 'Due Date',
				field: 'bill.due_date',
                type: 'datepicker',
                order: { form: 102 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 }, default: moment().format("YYYY-MM-DD") , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], 
            },
			{ 
				label: 'Currency',
				field: 'bill.currency',
				type: 'text',
				order: { form: 103 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Payment Method',
				field: 'bill.payment_method',
				type: 'text',
				order: { form: 104 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Tax Type',
				field: 'bill.tax_type',
				type: 'text',
				order: { form: 105 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Gross Amount',
				field: 'bill.gross_amount',
				type: 'number',
				order: { form: 106 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Bed Tax',
				field: 'bill.bed_tax',
				type: 'number',
				order: { form: 107 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Tax',
				field: 'bill.tax',
				type: 'number',
				order: { form: 108 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Discount Amount',
				field: 'bill.discount_amount',
				type: 'number',
				order: { form: 109 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Service Charge Amount',
				field: 'bill.service_charge_amount',
				type: 'number',
				order: { form: 110 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Net Amount',
				field: 'bill.net_amount',
				type: 'number',
				order: { form: 111 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
				label: 'Status',
				field: 'bill.status',
				type: 'number',
				order: { form: 112 }, 
				create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 6, lg: 3 } }], 
			},
			{ 
                label: 'Details',
				field: 'bill.details',
                type: 'repeater',
                section: { id: 3, label: 'Bill Details' },
                order: { form: 113 },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: { xs: 2, md: 1 }, repeatLabel: true }],
                children: [
					{ 
						label: 'Item',
						type: 'text',
						order: { form: 1 }, 
						create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12, lg: 3 } }], 
					},
					{ 
						label: 'Description',
						type: 'text',
						order: { form: 6 }, 
						create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12, lg: 9 } }], 
					},
					{ 
						label: 'Quantity',
						field: 'qty',
						type: 'number',
						order: { form: 3 }, 
						create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 4, lg: 3 } }], 
					},
					{ 
						label: 'Rate',
						type: 'number',
						order: { form: 4 }, 
						create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 4, lg: 3 } }], 
					},
					{ 
						label: 'Amount',
						type: 'number',
						order: { form: 5 }, 
						create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 4, lg: 3 } }], 
					},
				]
			}

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
