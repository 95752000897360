import axios from 'axios';
import echo from './echo';

const http = axios.create ({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 40000,
    headers: {'Content-Type': 'application/json'},
});

http.interceptors.request.use (
    function (config) {
        const token = localStorage.getItem('authToken');
        if (token){
            config.headers.Authorization = `Bearer ${token}`;
        }
        if(process.env.REACT_APP_WEBSOCKETS_ENABLED === 'true' && echo){
            config.headers["X-Socket-ID"] = echo.socketId()
        }
        return config;
    },
    function (error) {
        return Promise.reject (error);
    }
);

http.defaultCatch = (error) => ({"Status":'error',"Message":error.message, payload: error.response?.data})

export default http;
