import React from "react";

import {Link} from "react-router-dom";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

import { useRecoilValue } from "recoil";
import { notificationSettingsState } from "../../state/GlobalState";
import { notificationState } from "../../api/state/NotificationState";
import { authState, userState } from "../../api/state/AuthState";
import { MegaMenu } from "../../custom/components/MegaMenu";

const Header = props => {

  const notificationSettings = useRecoilValue(notificationSettingsState)
  const notifications = useRecoilValue(notificationState)
  const user = useRecoilValue(userState)
  const isAuth = useRecoilValue(authState)

  const toggleFullscreen = () => {
    if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const toggleMenu = () => {
    props.toggleMenuCallback();
  }

  return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="19" />
                  </span>
                </Link>
              </div>
              <button type="button" onClick={toggleMenu} className="btn btn-sm px-3 font-size-16 header-item waves-effect waves-light d-lg-none" data-toggle="collapse" data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>
              <MegaMenu />
            </div>
            <div className="d-flex">
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" onClick={toggleFullscreen} className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>
              {notificationSettings?.enabled && isAuth ? <NotificationDropdown notifications={notifications} settings={notificationSettings} /> : null}
              {isAuth ? <ProfileMenu user={user} /> : null}
            </div>
          </div>
        </header>
      </React.Fragment>
  );
}

export default (Header);
