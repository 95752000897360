import React from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { forwardRef } from "react";

const CheckboxEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value === "Yes" ? true : ( props.value === "No" ? false : parseInt(props.value) === 1));

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        };
    });

    return (
        <div className="custom-control custom-checkbox" style={{zIndex:0, padding: '10px 30px'}} onClick={() => setValue(!value)}>
            <input type="checkbox" style={{transform: 'scale(2)'}} className="custom-control-input" onChange={() => false} checked={Boolean(value)} />
            <label className="custom-control-label"></label>
        </div>
    );
});
CheckboxEditor.displayName = "CheckboxEditor";

export default CheckboxEditor;